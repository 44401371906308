import { Component, OnInit } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  overlayHidden: boolean = true;
  public currentRoute: any = '';

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private router: Router
  ) {
    this.initializeApp();
    this.router.events.subscribe((val) => {
      // see also 
      if(val instanceof NavigationEnd) {
        this.currentRoute = this.router['routerState'].snapshot.url;
      }
    });
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  setClasses() {
    return {
      'nobg': (this.currentRoute == '/home'),
      'hidden-nav': (this.currentRoute == '/native'),
    }
  }

  showOverlay() {
    this.overlayHidden = false;
  }
  hideOverlay() {
    this.overlayHidden = true;
  }

  openPrivacy(){
    window.open("https://www.dentamile.com/de/datenschutz", '_blank').focus();
  }
  openImprint(){
    window.open("https://www.dentamile.com/de/impressum", '_blank').focus();
  }

}
